<template>
  <div>
    <rectangle-element
      :text="content.body"
      :layout="layout.body"
      :skeleton="skeleton"
    ></rectangle-element>
    <image-element
      :url="content.downloadUrl"
      :layout="layout.image"
      :skeleton="skeleton"
      v-if="withImage"
    ></image-element>
    <text-element
      :text="content.name"
      :layout="layoutName"
      :skeleton="skeleton"
      :hasImage="withImage"
    ></text-element>
    <text-element
      :text="content.description"
      :layout="layoutDescription"
      :skeleton="skeleton"
      :hasImage="withImage"
    ></text-element>
    <my-business-info
      :web="content.web"
      :facebook="content.facebook"
      :instagram="content.instagram"
      :layout="layout.web"
      :skeleton="skeleton"
      :hasImage="withImage"
    ></my-business-info>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "MyBusinessElement",
  mixins: [layoutMixin],
  components: {
    TextElement: () => import("@/components/core/TextElement"),
    MyBusinessInfo: () => import("@/components/mybusiness/MyBusinessInfo"),
    RectangleElement: () => import("@/components/core/RectangleElement"),
    ImageElement: () => import("@/components/core/ImageElement"),
    //MyBusinessInfo: () => import("@/components/mybusiness/MyBusinessInfo"),
  },
  props: {
    content: Object,
  },
  data() {
    return {
      num: 0,
    };
  },
  mounted() {},
  destroyed() {},
  computed: {
    layoutDescription: function() {
      var description = cloneDeep(this.layout.description);
      description.color = this.content.fontColor;
      return description;
    },
    layoutName: function() {
      var name = cloneDeep(this.layout.name);
      name.color = this.content.fontColor;
      return name;
    },
    layoutBody: function() {
      var name = cloneDeep(this.layout.body);
      name.background = {};
      name.background.type = name.bodyContent;
      if (name.background.type === 0) {
        name.background.param = name.bodyBackground;
      } else {
        name.background.param = name.bodyImageUrl;
      }
      return name;
    },
    withImage: function() {
      if (this.content && this.content.downloadUrl) {
        return true;
      }
      return false;
    },
  },
  methods: {},
};
</script>
